import React,{useEffect} from 'react'
import { useNavigate } from 'react-router';
import PageHeader from '../components/ValidationPageComponent/PageHeader'
import tadobaLogo from '../assets/tadoba-logo.png'
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate=  useNavigate()
  const location = useLocation()


  // Use URLSearchParams to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // console.log("#### queryParams", queryParams)

  const pdfFileName = queryParams.get('filename');

  const dummyPdf = `https://safaribooking.mahaforest.gov.in/dept-api/pdfs/${pdfFileName}`
  useEffect(()=>{
    setTimeout(()=>{

      navigate("/paymentSlip",{state: {pdfUrl: dummyPdf}})
    },5000)
  })

  
  return (
    <div>
          <div style={{ marginTop: '60px' }}>
         <div className="card-header bg-white border-0">
        <div className="d-flex justify-content-center mt-300">
          <img src={tadobaLogo} alt="Tadoba National Park Logo"/>
          </div>
          </div>
        <div className='mt-5'>
          <div className="d-flex flex-column justify-content-center align-items-center" >
          <IoCheckmarkDoneCircleOutline style={{color: "green", width: 60, height:60}} />
          <div style={{color: "#7c7c7c", fontWeight: 600, fontSize: 50 }}>Your Booking is Confirmed</div>
          </div>
          <div className="d-flex justify-content-center mt-100">
          <div style={{color: "#7c7c7c", fontWeight: 600, fontSize: 25 }}>Booking details sent to your WhatsApp mobile number</div>
        </div>
        </div>
        </div>
      
    </div>
  )
}

export default PaymentSuccess
