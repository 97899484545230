import React, { useState, useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider , ErrorMessage} from 'formik';
import ShowMessage from "../components/ShowMessage/ShowMessage";
import { UseSelector,useDispatch } from "react-redux";
import { RootState, AppDispatch } from '../Redux-Store/store';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageHeader from "../components/ValidationPageComponent/PageHeader";
import PageFooter from "../components/ValidationPageComponent/PageFooter";
import { ResendTicketAction } from "../Redux-Store/actions/ResendTicketAction";
import ShowLoader from "../components/Loader/LoaderComponent";



type Props = {

};

const ResendTicket = (Props) => {

  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)
  const [open,setOpen] =useState(false)
  const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
  const [isLoaderShow, setLoaderShow] = useState(false)
const [errorMessage, setErrorMessage] = useState('');
const [mobileNumber, setMobileNumber] = useState('');
const [messageType, setMessageType] = useState<'success' | 'error'>('success')
const [sendData, setSendData] = useState()
  const navigate = useNavigate()
  const dispatch :AppDispatch =useDispatch()
  const { resendTicketData, message } = useSelector((state: RootState) => state.resendTicket);
console.log("resendTicketData", resendTicketData)
console.log("message", message)



  interface FormValues {
    numberField: string;
  }


  const handleBookingList =() =>{
    navigate('/bookingList')
  }

  const resendTicketSchema= Yup.object({
    numberField: Yup.string()
    .matches(/^[0-9]+$/, 'Only numbers are allowed') // Ensure only numbers are allowed
    .max(10, 'Mobile number must be at most 10 digits')
    .required('WhatsApp mobile number is Required'),
  })


  const backtoSelectionPage = () =>{
    navigate('/date-selection')
  }




  const formik= useFormik<FormValues>({
    initialValues: {
      numberField:''
    },
    validationSchema: resendTicketSchema,
    onSubmit:(value)=>{
      setLoaderShow(true);
      console.log(value)
      dispatch(ResendTicketAction({
        "mobile_number": value.numberField
      })).then((response)=>{
        console.log("response", response.payload.data)
        if(response.payload.meta.is_error === false){
        setErrorMessage(response?.payload?.meta?.message);
        setMessageType('success');
        setIsShow(true);
        setNavigateAfterMessage(true);
        setLoaderShow(false);
        setSendData(response.payload.data)
      }else if (response?.payload.meta.is_error === true) {
    
        setMessageType('error')
        setErrorMessage(response?.payload?.meta?.message);
        setIsShow(true);
        setLoaderShow(false);
        setNavigateAfterMessage(false);
      } else {
        console.log('else');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
setTimeout(() => {setIsShow(false)}, 2000);
  }
})

 

  useEffect(() => {
    if (navigateAfterMessage) {
      const timer = setTimeout(() => {
        navigate('/bookingList', {state: sendData} );
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [navigateAfterMessage, navigate, sendData]);



  const { errors, touched, handleSubmit, getFieldProps } = formik;

  
  return (
    <>

{/* <section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader /> */}

<section className="container validationPage">
      <div className="row align-items-center height100">
        <div className="col-md-12">
          <div className="card">
            <PageHeader />
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <form action="" className="w-50" onSubmit={handleSubmit}>
            <div className="input-group input-group-lg flex-nowrap">
              <input
                type="tel"
                maxLength={10}
                className="form-control"
                placeholder="Enter WhatsApp Mobile Number"
                aria-label="WhatsApp Mobile Number"
                aria-describedby="addon-wrapping"
                {...getFieldProps('numberField')}
                inputMode="numeric"
                pattern="[0-9]*"
                onInput={(e:any) => (e.target.value = e.target.value.replace(/\D/g, ""))}
                onKeyDown={(e) => {
                  if (e.key.length === 1 && /\D/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <span className="input-group-text bg-white" id="addon-wrapping">
                <IoLogoWhatsapp className="textSuccessDark" />
              </span>
            </div>
            {touched.numberField && errors.numberField ? (
                      <div style={{ color: "red" }}>
                        {errors.numberField}
                      </div>
                    ) : null}

            <div className="mt-4">
              <button
                className="btnPrimary btn-lg w-100"
                type="submit"
                // onClick={handleBookingList}
              >
              Resend Tickets
              </button>
              <button
              onClick={backtoSelectionPage}
                className="btnSecondary btn-lg w-100 linkbtn-block mt-3"
              >
              Back
              </button>
            </div>
          </form>
        </div>
      </div>
      <PageFooter />
          </div>
        </div>
      </div>
    </section>
      {/* <PageFooter />
          </div>
        </div>
      </div>
    </section> */}
    <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={messageType}
        open={isShow}
      />
      <ShowLoader open={isLoaderShow}/>
    </>
  );
};

export default ResendTicket;
