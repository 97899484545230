import React,{useEffect} from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../Redux-Store/store";
import {getIdCardDetails} from '../../Redux-Store/actions/ConfirmBookingdetails'

interface Props {
  seatNumber: number;
  name: string;
  age: string;
  gender: string;
  cardtype_name:string;
  idcard_value: string
  // handleSubmit:()=>void;
  onPassengerChange: (seatNumber: number,
    name: string,
    age: string,
    gender: string,
    cardtype_name: string,
    idcard_value: string,
    isValid : boolean
  ) => void;
  onValidate: any; // New prop for validation
}






const PassengerInfo = ({ seatNumber, name, age, gender,cardtype_name, idcard_value, onPassengerChange, onValidate }: Props) => {

  const dispatch: AppDispatch = useDispatch();

  const {idCard} = useSelector((state:RootState)=>state?.idCard)
  console.log("idCard",idCard)


  const idCardDetails =  idCard|| []

  console.log("idCardDetails", idCardDetails)

  

  const optionGender = [
    { id: 1, value: 'Male' },
    { id: 2, value: 'Female' },
    { id: 3, value: 'Other' },
  ];

  const formik = useFormik({
    initialValues: {
      name,
      age,
      gender,
      cardtype_name,
      idcard_value
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),

      age: Yup.string()
      .required("Age is Required")
      .matches(/^\d+$/, "Age must be a valid number") // Ensures only digits
      .test("min-age", "Age must be 5 or greater", (value) => Number(value) >= 5)
      .test("max-age", "Age must be between 5 to 100", (value) => Number(value) <= 100),

      gender: Yup.string().required("Gender is Required"),
      cardtype_name: Yup.string().required("Id Type is Required"),

      idcard_value: Yup.string()
      .required("Id Number is Required")
      .when("cardtype_name", (cardtype_name, schema) => {
        if (cardtype_name[0]?.toLowerCase() === "aadhar card") {
          return schema.matches(/^\d{12}$/, "Aadhar card must be exactly 12 digits");
        }else if (cardtype_name[0]?.toLowerCase() === "pan card") {
          return schema.matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN card format (e.g., ABCDE1234A)");
        }else if (cardtype_name[0]?.toLowerCase() === "driving license") {
          return schema.matches(/^[A-Z]{2}\d{2}\d{1,11}$/, "Invalid Driving License format (e.g., SSYY1234567)");
        }else if (cardtype_name[0]?.toLowerCase() === "passport") {
          return schema.matches(/^[A-Z]{1}[0-9]{7}$/, "Invalid Passport format (e.g., A1234567)");
        }else if (cardtype_name[0]?.toLowerCase() === "voter id card") {
          return schema.matches(/^[A-Z]{3}[0-9]{7}$/, "Invalid Voter ID format (e.g., ABC1234567)");
        }
        return schema;
      }),

    }),
    validateOnMount: true, // This forces validation immediately
    onSubmit: (value) => {
 console.log("value", value)
//  onPassengerChange(seatNumber, value.name, value.age, value.gender);
    },
  });

  // Update passenger details on change
  useEffect(() => {
    onPassengerChange(seatNumber,  formik.values.name, formik.values.age, formik.values.gender, formik.values.cardtype_name, formik.values.idcard_value, formik.isValid);
  }, [formik.values, seatNumber, formik.errors]);

  useEffect(() => {

    dispatch(getIdCardDetails({})); // Call the action directly
  }, [dispatch]);
  

  // Handle validation on confirm
  React.useEffect(() => {
    if (onValidate) {
      onValidate();
    }
  }, [formik.touched, formik.errors]);


  const { errors, touched, getFieldProps } = formik;
  const handleNameChange = (e) =>{
    formik.setFieldValue('name',e.target.value)
  }

  return (
    <div className="d-flex gap-2 my-3 justify-content-between">
      <div>
      <input
        type="text"
        className={`form-control`}
        placeholder="Passenger Name"
        name="name"
        value={formik.values.name}
        onChange={(e) =>{formik.handleChange(e) 
        handleNameChange(e)
        }}
             onBlur={formik.handleBlur}
        // {...getFieldProps('name')}
      />
        {touched.name && errors.name ? (
                        <div style={{ color: "red", marginLeft:10}}>
                          {errors.name}
                        </div>
                      ) : null}
      </div>
      <div>
      <input
        type="tel"
        className={`form-control age`}
        placeholder="Enter Age"
        name="age"
        {...getFieldProps('age')}
        onKeyDown={(e) => {
          if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
            e.preventDefault();
          }
        }}
        onChange={(e)=> {
          const value = e.target.value;
          if (Number(value) <= 100) {
            formik.setFieldValue("age", value);
          }
        }}
      />
        {touched.age && errors.age ? (
                        <div style={{ color: "red", marginLeft:10}}>
                          {errors.age}
                        </div>
                      ) : null}

       </div>
       <div>
      
      <select
        name="gender"
        className={`form-select`}
      defaultValue=""
      {...getFieldProps('gender')}
      >
        <option value="" disabled>Select Gender</option>
        {optionGender.map(genderOption => (
          <option key={genderOption.id} value={genderOption.value}>{genderOption.value}</option>
        ))}
      </select>
      {touched.gender && errors.gender ? (
                        <div style={{ color: "red",marginLeft:10}}>
                          {errors.gender}
                        </div>
                      ) : null}

    </div>
    <div>
      
      <select
        name="cardtype_name"
        className={`form-select`}
      defaultValue=""
      {...getFieldProps('cardtype_name')}
      >
        <option value="" disabled>Select Id</option>
        {idCardDetails?.map(selectId => (
          <option key={selectId.cardtype_id} value={selectId.cardtype_name}>{selectId.cardtype_name}</option>
        ))}
      </select>
      {touched.cardtype_name && errors.cardtype_name ? (
                        <div style={{ color: "red",marginLeft:10}}>
                          {errors.cardtype_name}
                        </div>
                      ) : null}

    </div>
    <div>
    <input
        type="text"
        className={`form-control`}
        placeholder="Enter Id Number"
        name="idcard_value"
        value={formik.values.idcard_value}
        onBlur={formik.handleBlur}
        onChange={(e) => {
          const uppercaseValue = e.target.value.trim().toUpperCase();
          formik.setFieldValue('idcard_value', uppercaseValue);
        }}
        // {...getFieldProps('idcard_value')}
      />
        {touched.idcard_value && errors.idcard_value ? (
                        <div style={{ color: "red", marginLeft:10}}>
                          {errors.idcard_value}
                        </div>
                      ) : null}
      </div>
    </div>
  );
};

export default PassengerInfo;
