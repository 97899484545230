import { createSlice } from '@reduxjs/toolkit';
import { getIdCardDetails } from '../actions/ConfirmBookingdetails';


const IdCardSlice=createSlice({
    name:'idCard',
    initialState:{
        idCard:[],
        loading:false,
        error:null,
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getIdCardDetails.pending,(state,action)=>{
            state.loading=true;
            state.idCard=[]

        })
        builder.addCase(getIdCardDetails.fulfilled, (state,action)=>{
            state.loading= false;
            state.idCard=action.payload.data
            console.log("idcard",state.idCard)
        })
        builder.addCase(getIdCardDetails.rejected,(state,action)=>{
            state.loading=false;
            state.idCard=[];
            state.error=action.error.message
        })
    }
})

export default IdCardSlice.reducer;