import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'

const PaymentSlip: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  console.log('location', location.state.pdfUrl)
  const pdfView = location.state.pdfUrl|| ''

  useEffect(()=>{    
    setTimeout(()=>{
      navigate('/')
    },30000)
  },[])


  return (
    <div>
       <iframe src={pdfView} width="100%" height="600px" />
    </div>
  )
}

export default PaymentSlip
