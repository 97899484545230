import React from 'react';
import { Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { CheckCircle, ExclamationCircle } from 'react-bootstrap-icons'; // Bootstrap icons



interface ShowLoaderProps {
  open: boolean;
}
const ShowLoader: React.FC<ShowLoaderProps> = ({ open }) => {
  if (!open) return null;

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "24px"
    }}>
      <Spinner animation="border" variant="warning" style={{ width: "100px", height: "100px" }} >
      </Spinner>
    </div>
  );
};

export default ShowLoader;